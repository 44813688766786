import React, { Suspense, useEffect } from "react";
import "./App.scss";
import TagManager from "react-gtm-module";
import {
  RouterProvider,
  createBrowserRouter,
  // redirect,
  // useLocation,
} from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
// import Home from "./pages/Home";
// import Timeline from "./pages/Timeline/Timeline";
// import TimelineDetail from "./pages/Timeline/TimelineDetail";
// import Fmenu from "./pages/Fmenu";
// import Request from "./pages/Request/Request";
// import Mypage from "./pages/Mypage/Mypage";
// import Agree from "./pages/Agree";
// import NewPost from "./pages/NewPost";
// import RequestInfo from "./pages/Request/RequestInfo";
// import RequestDetails from "./pages/Request/RequestDetails";
// import RequestDetails11st from "./pages/11st/RequestDetails_11st";
// import RequestRepairSelect from "./pages/Request/RequestRepairSelect";
// import IntegratedLogin from "./pages/IntegratedLogin";
// import Settings from "./pages/Mypage/Settings";
// import PrivacyPolicy from "./pages/Mypage/PrivacyPolicy";
// import Terms from "./pages/Mypage/Terms";
// import ModifyMember from "./pages/Mypage/ModifyMember";
// import WithDraw from "./pages/Mypage/WithDraw";
// import ManagePassword from "./pages/Mypage/ManagePassword";
// import ForCash from "./pages/Mypage/ForCash";
// import ForCoupon from "./pages/Mypage/ForCoupon";
// import Notification from "./pages/Mypage/Notification";
// import ReviewAll from "./pages/Review/ReviewAll";
// import ReviewDetail from "./pages/Review/ReviewDetail";
// import ReviewReport from "./pages/Review/ReviewReport";
// import MyReview from "./pages/MyReview/MyReview";
// import MyReviewWrite from "./pages/MyReview/MyReviewWrite";
// import MyReviewModify from "./pages/MyReview/MyReviewModify";
// import Meister from "./pages/Meister";
// import SignUpPhone from "./components/UserSign/SignUpPhone";
// import SignUpPassword from "./components/UserSign/SignUpPassword";
// import SignUpNameBirth from "./components/UserSign/SignUpNameBirth";
// import SignUpAgree from "./components/UserSign/SignUpAgree";
// import StoreInfo from "./pages/StoreInfo";
// import Service from "./pages/Mypage/Service";
// import CompareEstimate from "./pages/CompareEstimate";
// import UserGuide from "./pages/UserGuide";
// import RepairGuide from "./pages/RepairGuide";
// import ContactWith from "./pages/ContactWith";
// import BannerSlideDetail from "./components/BannerSlideDetail";
// import CertPhone from "./components/CertPhone";
// import ChangePassword from "./components/ChangePassword";
// import ServieInfo from "./pages/ServiceInfo";
// import Payment from "./pages/Payment/Payment";
// import RequestGuide from "./pages/RequestGuide";
// import RequestBrand11st from "./pages/11st/RequestBrand_11st";
// import RquestItems11st from "./pages/11st/RequestItems_11st";
// import RquestTexts11st from "./pages/11st/RequestTexts_11st";
// import VerifyMobile11st from "./pages/11st/VerrifyMobile_11st";
// import Complete11st from "./pages/11st/Complete_11st";
// import Estimate11st from "./pages/11st/Estimate_11st";
// import Boarding11st from "./pages/11st/Boarding_11st";
// import PaymentResult from "./pages/Payment/PaymentResult";
// import ShippingInfo from "./pages/Mypage/ShippingInfo";
// import Shilla from "./pages/Shilla/Shilla";
// import ContentsDetail from "./pages/Contents/ContentsDetail";
// import Event from "./pages/Mypage/Event";
// import PromotionDetail1 from "./pages/Mypage/PromotionDetail1";
// import Partners from "./pages/Mypage/Partners";
// import ErrorPage from "./styles/pages/ErrorPage";
// import Magazine from "./pages/Contents/Magazine";
// import BrandStory from "./pages/Contents/BrandStory";
// import News from "./pages/Contents/News";
// import PrivateRoute from "./route/PrivateRoute";
// import PromoLanding from "./pages/promo/Landing";

const Home = React.lazy(() => import("./pages/Home"));
const Fmenu = React.lazy(() => import("./pages/Fmenu"));
const Request = React.lazy(() => import("./pages/Request/Request"));
const Mypage = React.lazy(() => import("./pages/Mypage/Mypage"));
const Agree = React.lazy(() => import("./pages/Agree"));
const NewPost = React.lazy(() => import("./pages/NewPost"));
const ModifyMember = React.lazy(() => import("./pages/Mypage/ModifyMember"));
const RequestInfo = React.lazy(() => import("./pages/Request/RequestInfo"));
const RequestDetails = React.lazy(
  () => import("./pages/Request/RequestDetails")
);
const RequestDetails11st = React.lazy(
  () => import("./pages/11st/RequestDetails_11st")
);
const RequestDetailsHomeniq = React.lazy(
  () => import("./pages/Homeniq/RequestDetails_Homeniq")
);
const RequestRepairSelect = React.lazy(
  () => import("./pages/Request/RequestRepairSelect")
);
const IntegratedLogin = React.lazy(() => import("./pages/IntegratedLogin"));
const Settings = React.lazy(() => import("./pages/Mypage/Settings"));
const PrivacyPolicy = React.lazy(() => import("./pages/Mypage/PrivacyPolicy"));
const Terms = React.lazy(() => import("./pages/Mypage/Terms"));
const WithDraw = React.lazy(() => import("./pages/Mypage/WithDraw"));
const ManagePassword = React.lazy(
  () => import("./pages/Mypage/ManagePassword")
);
const ForCash = React.lazy(() => import("./pages/Mypage/ForCash"));
const ForCoupon = React.lazy(() => import("./pages/Mypage/ForCoupon"));
const Notification = React.lazy(() => import("./pages/Mypage/Notification"));
const ReviewReport = React.lazy(() => import("./pages/Review/ReviewReport"));
const MyReview = React.lazy(() => import("./pages/MyReview/MyReview"));
const MyReviewWrite = React.lazy(
  () => import("./pages/MyReview/MyReviewWrite")
);
const MyReviewModify = React.lazy(
  () => import("./pages/MyReview/MyReviewModify")
);
const Meister = React.lazy(() => import("./pages/Meister"));
//
const SignUpPhone = React.lazy(
  () => import("./components/UserSign/SignUpPhone")
);
const SignUpPassword = React.lazy(
  () => import("./components/UserSign/SignUpPassword")
);
const SignUpNameBirth = React.lazy(
  () => import("./components/UserSign/SignUpNameBirth")
);
const SignUpAgree = React.lazy(
  () => import("./components/UserSign/SignUpAgree")
);
const StoreInfo = React.lazy(() => import("./pages/StoreInfo"));
const Service = React.lazy(() => import("./pages/Mypage/Service"));
const CompareEstimate = React.lazy(() => import("./pages/CompareEstimate"));
const UserGuide = React.lazy(() => import("./pages/UserGuide"));
const RepairGuide = React.lazy(() => import("./pages/RepairGuide"));
const ContactWith = React.lazy(() => import("./pages/ContactWith"));
const BannerSlideDetail = React.lazy(
  () => import("./components/BannerSlideDetail")
);
const CertPhone = React.lazy(() => import("./components/CertPhone"));
const ChangePassword = React.lazy(() => import("./components/ChangePassword"));
const ServiceInfo = React.lazy(() => import("./pages/ServiceInfo"));
const Payment = React.lazy(() => import("./pages/Payment/Payment"));
const RequestGuide = React.lazy(() => import("./pages/RequestGuide"));
const RequestBrand11st = React.lazy(
  () => import("./pages/11st/RequestBrand_11st")
);
const RequestBrandHomeniq = React.lazy(
  () => import("./pages/Homeniq/RequestBrand_Homeniq")
);
const Magazine = React.lazy(() => import("./pages/Contents/Magazine"));
const BrandStory = React.lazy(() => import("./pages/Contents/BrandStory"));
const News = React.lazy(() => import("./pages/Contents/News"));
const ReviewAll = React.lazy(() => import("./pages/Review/ReviewAll"));
const ReviewDetail = React.lazy(() => import("./pages/Review/ReviewDetail"));
const Timeline = React.lazy(() => import("./pages/Timeline/Timeline"));
const TimelineDetail = React.lazy(
  () => import("./pages/Timeline/TimelineDetail")
);

const RquestItems11st = React.lazy(
  () => import("./pages/11st/RequestItems_11st")
);
const RquestTexts11st = React.lazy(
  () => import("./pages/11st/RequestTexts_11st")
);
const VerifyMobile11st = React.lazy(
  () => import("./pages/11st/VerrifyMobile_11st")
);
const Complete11st = React.lazy(() => import("./pages/11st/Complete_11st"));
const Estimate11st = React.lazy(() => import("./pages/11st/Estimate_11st"));
const Boarding11st = React.lazy(() => import("./pages/11st/Boarding_11st"));

const RquestItemsHomeniq = React.lazy(
  () => import("./pages/Homeniq/RequestItems_Homeniq")
);
const RquestTextsHomeniq = React.lazy(
  () => import("./pages/Homeniq/RequestTexts_Homeniq")
);
const VerifyMobileHomeniq = React.lazy(
  () => import("./pages/Homeniq/VerrifyMobile_Homeniq")
);
const CompleteHomeniq = React.lazy(
  () => import("./pages/Homeniq/Complete_Homeniq")
);
const EstimateHomeniq = React.lazy(
  () => import("./pages/Homeniq/Estimate_Homeniq")
);
const BoardingHomeniq = React.lazy(
  () => import("./pages/Homeniq/Boarding_Homeniq")
);

const PaymentResult = React.lazy(() => import("./pages/Payment/PaymentResult"));
const ShippingInfo = React.lazy(() => import("./pages/Mypage/ShippingInfo"));
const Shilla = React.lazy(() => import("./pages/Shilla/Shilla"));
const ContentsDetail = React.lazy(
  () => import("./pages/Contents/ContentsDetail")
);
const Event = React.lazy(() => import("./pages/Mypage/Event"));
const PromotionDetail1 = React.lazy(
  () => import("./pages/Mypage/PromotionDetail1")
);
const Partners = React.lazy(() => import("./pages/Mypage/Partners"));
//
const ErrorPage = React.lazy(() => import("./styles/pages/ErrorPage"));
const PrivateRoute = React.lazy(() => import("./route/PrivateRoute"));
const PromoLanding = React.lazy(() => import("./pages/promo/Landing"));

interface TagManagerArgs {
  gtmId: string;
}
const tagManagerArgs: TagManagerArgs = {
  gtmId: String(process.env.REACT_APP_GOOGLE_TAGMANAGER_TRACKING_ID),
};
TagManager.initialize(tagManagerArgs);

function App() {
  // 100vh
  function setScreenSize() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }
  setScreenSize();
  window.addEventListener("resize", setScreenSize);

  // private route
  // const loader = async () => {
  //   if (!localStorage.getItem("Authorization")) {
  //     const exDays = new Date();
  //     const exTime = new Date();
  //     // d.setMinutes(exdays.getMinutes() + 1);
  //     exTime.setHours(exDays.getHours() + 12);
  //     // expires = expires.setHours(expires.getHours() + 24);
  //     const expires = `expires=${exTime.toUTCString()}`;
  //     const path = `latestPath=${window.location.pathname}; ${expires}`;
  //     document.cookie = path;
  //   }
  //   try {
  //     await api.get("/my/user");
  //   } catch (error: any) {
  //     if (error.response.status === 401) {
  //       window.alert("로그인이 필요한 서비스입니다.");
  //       return redirect("/integrated-login");
  //     }
  //   }
  //   return null;
  // };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorPage />,
      children: [
        // timeline
        {
          path: "/timeline",
          // element: <Timeline />,
          // loader: () => loader(),
          element: <PrivateRoute element={<Timeline />} />,
        },
        {
          path: "/timeline/:id",
          element: <PrivateRoute element={<TimelineDetail />} />,
        },
        // myreview
        {
          path: "/myreview",
          element: <PrivateRoute element={<MyReview />} />,
        },
        {
          path: "/myreview/write/:id",
          element: <PrivateRoute element={<MyReviewWrite />} />,
        },
        {
          path: "/myreview/modify/:id",
          element: <PrivateRoute element={<MyReviewModify />} />,
        },
        // review
        {
          path: "/review",
          element: <ReviewAll />,
        },
        {
          path: "/review/:id",
          element: <ReviewDetail />,
        },
        {
          path: "/review/:id/report",
          element: <PrivateRoute element={<ReviewReport />} />,
        },
        // contents
        {
          path: "/brandstory",
          element: <BrandStory />,
        },
        {
          path: "/magazine",
          element: <Magazine />,
        },
        {
          path: "/brandstory/:id",
          element: <ContentsDetail />,
        },
        {
          path: "/magazine/:id",
          element: <ContentsDetail />,
        },
        {
          path: "/news",
          element: <News />,
        },
        // Request
        {
          path: "/request-info",
          element: <RequestInfo />,
        },
        {
          path: "/request",
          element: <PrivateRoute element={<Request />} />,
        },
        {
          path: "/request/select",
          element: <PrivateRoute element={<RequestRepairSelect />} />,
        },
        {
          path: "/request/details",
          element: <PrivateRoute element={<RequestDetails />} />,
        },
        // mypage
        {
          path: "/mypage",
          element: <Mypage />,
        },
        {
          path: "/newpost",
          element: <PrivateRoute element={<NewPost />} />,
        },
        {
          path: "/managepost",
          element: <PrivateRoute element={<ShippingInfo />} />,
        },
        {
          path: "/forcash",
          element: <PrivateRoute element={<ForCash />} />,
        },
        {
          path: "/forcoupon",
          element: <PrivateRoute element={<ForCoupon />} />,
        },
        {
          path: "/integrated-login",
          element: <IntegratedLogin />,
        },
        {
          path: "/sign-up-phone",
          element: <SignUpPhone />,
        },
        {
          path: "/sign-up-password",
          element: <SignUpPassword />,
        },
        {
          path: "/sign-up-name-birth",
          element: <SignUpNameBirth />,
        },
        {
          path: "/sign-up-agree",
          element: <SignUpAgree />,
        },
        {
          path: "/cert-phone/:id",
          element: <PrivateRoute element={<CertPhone />} />,
        },
        {
          path: "/settings",
          element: <PrivateRoute element={<Settings />} />,
        },
        {
          path: "/modify-member",
          element: <PrivateRoute element={<ModifyMember />} />,
        },
        {
          path: "/change-password",
          element: <PrivateRoute element={<ChangePassword />} />,
        },
        {
          path: "/password",
          element: <PrivateRoute element={<ManagePassword />} />,
        },
        {
          path: "/withdraw",
          element: <PrivateRoute element={<WithDraw />} />,
        },
        // payment
        {
          path: "/agree",
          element: <PrivateRoute element={<Agree />} />,
        },
        {
          path: "/payment",
          element: <PrivateRoute element={<Payment />} />,
        },
        {
          path: "/payment-result",
          element: <PrivateRoute element={<PaymentResult />} />,
        },
        // compare-estimate
        {
          path: "/meister",
          element: <Meister />,
        },
        {
          path: "/compare-estimate/:id",
          element: <PrivateRoute element={<CompareEstimate />} />,
        },
        // etc
        {
          path: "/policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "/terms",
          element: <Terms />,
        },
        {
          path: "/notification",
          element: <PrivateRoute element={<Notification />} />,
        },
        {
          path: "/store-info",
          element: <StoreInfo />,
        },
        {
          path: "/service",
          element: <Service />,
        },
        {
          path: "/event",
          element: <Event />,
        },
        {
          path: "/partners",
          element: <Partners />,
        },
        {
          path: "/event/:id",
          element: <PromotionDetail1 />,
        },
        {
          path: "/partners/:id",
          element: <PromotionDetail1 />,
        },
        {
          path: "/guide",
          element: <UserGuide />,
        },
        {
          path: "/request-guide",
          element: <RequestGuide />,
        },
        {
          path: "/repair-guide",
          element: <RepairGuide />,
        },
        {
          path: "/contact",
          element: <ContactWith />,
        },
        {
          path: "/slide-detail",
          element: <BannerSlideDetail />,
        },
        {
          path: "/service-info",
          element: <ServiceInfo />,
        },
        // event > shilla
        {
          path: "/shilla",
          element: <Shilla />,
        },
        // event > 11st
        {
          path: "/11st/details",
          element: <RequestDetails11st />,
        },
        {
          path: "/11st/brand",
          element: <RequestBrand11st />,
        },
        {
          path: "/11st/items",
          element: <RquestItems11st />,
        },
        {
          path: "/11st/texts",
          element: <RquestTexts11st />,
        },
        {
          path: "/11st/verify",
          element: <VerifyMobile11st />,
        },
        {
          path: "/11st/complete",
          element: <Complete11st />,
        },
        {
          path: "/11st/estimate",
          element: <Estimate11st />,
        },
        {
          path: "/11st/boarding",
          element: <Boarding11st />,
        },
        // event > Homeniq
        {
          path: "/Homeniq/details",
          element: <RequestDetailsHomeniq />,
        },
        {
          path: "/Homeniq/brand",
          element: <RequestBrandHomeniq />,
        },
        {
          path: "/Homeniq/items",
          element: <RquestItemsHomeniq />,
        },
        {
          path: "/Homeniq/texts",
          element: <RquestTextsHomeniq />,
        },
        {
          path: "/Homeniq/verify",
          element: <VerifyMobileHomeniq />,
        },
        {
          path: "/Homeniq/complete",
          element: <CompleteHomeniq />,
        },
        {
          path: "/Homeniq/estimate",
          element: <EstimateHomeniq />,
        },
        {
          path: "/Homeniq/boarding",
          element: <BoardingHomeniq />,
        },
        // promotion
        {
          path: "/promo-landing",
          element: <PromoLanding />,
        },
        // menu
        {
          path: "/menu",
          element: <Fmenu />,
        },
      ],
    },
  ]);
  useEffect(() => {
    const cdnScript: any = document.querySelector(`script[class="gtag-cdn"]`);
    const isScript: any = document.querySelector(`script[class="gtag-init"]`);
    if (!isScript && !cdnScript) {
      const newCdnScript = document.createElement("script");
      newCdnScript.async = true;
      newCdnScript.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID}`;
      newCdnScript.setAttribute("class", "gtag-cdn");

      const script = document.createElement("script");
      script.setAttribute("class", "gtag-init");
      script.async = true;
      script.type = "text/javascript";
      script.text = `
        window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag("js", new Date());

          gtag("config", "${process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID}");
      `;

      // 스크립트 추가
      document.head.prepend(script);
      document.head.prepend(newCdnScript);
    } else {
      document.head.removeChild(isScript);
      document.head.removeChild(cdnScript);
      const newCdnScript = document.createElement("script");
      newCdnScript.async = true;
      newCdnScript.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID}`;
      newCdnScript.setAttribute("class", "gtag-cdn");
      const script = document.createElement("script");
      script.setAttribute("class", "gtag-init");
      script.async = true;
      script.type = "text/javascript";
      script.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "${process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID}");
      `;
      // 스크립트 추가
      document.head.prepend(script);
      document.head.prepend(newCdnScript);
    }
  }, []);

  return (
    <div className="app">
      <Suspense>
        <RouterProvider router={router} />
      </Suspense>
    </div>
  );
}

export default App;
