/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

interface collects {
  collectImgFile: File;
  collectViewFile: string;
  collectTextFile?: string;
}
// {
//   collectImgFile: "",
//   collectViewFile: "",
//   collectTextFile: "",
// },

export const requestSlice = createSlice({
  name: "request",
  initialState: {
    requestStep1: {
      requestType: null,
    },
    requestStep2: {
      brand: "",
      brandNumber: null,
      item: "",
      itemNumber: null,
      unit: "",
    },
    requestStep3: [
      { collectImgFile: undefined, collectViewFile: undefined },
      { collectImgFile: undefined, collectViewFile: undefined },
      { collectImgFile: undefined, collectViewFile: undefined },
      { collectImgFile: undefined, collectViewFile: undefined },
      { collectImgFile: undefined, collectViewFile: undefined },
      { collectImgFile: undefined, collectViewFile: undefined },
    ],
    requestStep3Text: "",
    requestStep3Require: [],
    promo11stInfo: {
      qcode: "",
      uuid: "",
      deviceid: "",
      hash: "",
    },

    // requestStep3Files: [null, null, null, null, null, null],
    // requestStep3Urls: [null, null, null, null, null, null],
    // requestStep3: {
    //   collectImgFiles: [],
    //   collectViewFiles: [],
    //   collectTextFiles: [],
    // },
  },
  reducers: {
    setRequestStep1: (state, action) => {
      state.requestStep1.requestType = action.payload;
      // console.log("setRequestStep1", state, action);
    },
    setRequestStep2: (state, action) => {
      state.requestStep2 = action.payload;
      // console.log("setRequestStep2", state, action);
    },
    setRequestStep3: (state, action) => {
      // console.log("requestStep3", state, action);
      // state.requestStep3.push(action.payload);
      // console.log(state.requestStep3);
      // console.log(
      //   action.payload.collectImgFile,
      //   action.payload.collectViewFile
      // );
      state.requestStep3[action.payload.currentIndex].collectImgFile =
        action.payload.collectImgFile;
      state.requestStep3[action.payload.currentIndex].collectViewFile =
        action.payload.collectViewFile;
    },
    setRequestStep3Require: (state, action) => {
      state.requestStep3Require = action.payload;
    },
    set11stPromoInfo: (state, action) => {
      // console.log(action.payload);
      state.promo11stInfo.deviceid = action.payload.deviceid;
      state.promo11stInfo.qcode = action.payload.qcode;
      state.promo11stInfo.uuid = action.payload.uuid;
      state.promo11stInfo.hash = action.payload.hash;
    },
    // setRequestStep3Files: (state, action) => {
    //   console.log(state, action);
    //   const indices = [];
    //   const array = state.requestStep3Files;
    //   let idx = array.indexOf(null);
    //   while (idx !== -1) {
    //     indices.push(idx);
    //     idx = array.indexOf(null, idx + 1);
    //   }

    //   for (let i = 0; i < action.payload.collectImgFile.length; i++) {
    //     console.log(
    //       state.requestStep3Files[i],
    //       action.payload.collectImgFile[i],
    //       action.payload.collectViewFile[i]
    //     );
    //     state.requestStep3Files[indices[i]] = action.payload.collectImgFile[i];
    //     // state.requestStep3Urls[indices[i]] = action.payload.collectViewFile[i];
    //   }
    //   console.log(indices);
    //   console.log("?", array);
    //   console.log("!", state.requestStep3Urls);
    // },
    setRequestStep3Text: (state, action) => {
      // console.log(state, action, "???tet?");
      state.requestStep3Text = action.payload;
      // state.requestStep3.push(action.payload);
      // console.log("requestStep3!!!", action.payload, state, action);
      // state.requestStep3[action.payload.index].collectTextFile =
      //   action.payload.ts;
    },
    chageRequestStep3: (state, action) => {
      // console.log("zzz", state, action);
      state.requestStep3[action.payload.index].collectImgFile = undefined;
      state.requestStep3[action.payload.index].collectViewFile = undefined;
    },
    deleteRequestStep3: (state, action) => {
      // console.log("deleteRequestStep3", state, action);
      state.requestStep3.splice(action.payload.index, 1);
    },
    deleteAllRequestStep3: (state, action) => {
      // console.log("deleteRequestStep3", state, action);
      // state.requestStep3.splice(0);
      state.requestStep3 = [
        { collectImgFile: undefined, collectViewFile: undefined },
        { collectImgFile: undefined, collectViewFile: undefined },
        { collectImgFile: undefined, collectViewFile: undefined },
        { collectImgFile: undefined, collectViewFile: undefined },
        { collectImgFile: undefined, collectViewFile: undefined },
        { collectImgFile: undefined, collectViewFile: undefined },
      ];
    },
  },
});

export const stateRequest = function (state: any) {
  // // console.log("stateMyInfo", state);
  return state.request;
};

export const actionRequest = requestSlice.actions;

export default requestSlice.reducer;
