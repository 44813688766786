/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const promotionSlice = createSlice({
  name: "promotion",
  initialState: {
    ingPromotion: {},
    expPromotion: {},
    repairGuides: {},
  },
  reducers: {
    setPromotion: (state, action) => {
      state.ingPromotion = action.payload;
      // console.log("ingPromotion", state, action);
    },
    setPromotionExp: (state, action) => {
      state.expPromotion = action.payload;
      // console.log("expPromotion", state, action);
    },
    setRepairGuide: (state, action) => {
      state.repairGuides = action.payload;
      // console.log("repairGuide", state, action);
    },
  },
});

export const statePromotion = function (state: any) {
  return state.promotion;
};

export const actionPromotion = promotionSlice.actions;

export default promotionSlice.reducer;
