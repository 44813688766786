/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentTimeline: [],
  pastTimeline: [],
  carouselPopupState: false,
  loading: true,
  imageSliderPopup: false,
  imageSliderData: null,
  imageSliderStartIndex: null,
};

const timelineSlice = createSlice({
  name: "timeline",
  initialState,
  reducers: {
    handleLoadingSpinner(state, action) {
      state.loading = action.payload.loading;
    },
    getCurrentTimeline(state, action) {
      state.currentTimeline = action.payload.currentTimeline;
    },
    getPastTimeline(state, action) {
      state.pastTimeline = action.payload.pastTimeline;
    },
    changeCarouselPopupState(state, action) {
      state.carouselPopupState = action.payload.carouselPopupState;
    },
    // imageSlider
    setImageSliderPopup(state, action) {
      state.imageSliderPopup = action.payload.imageSliderPopup;
    },
    setImageSliderData(state, action) {
      state.imageSliderData = action.payload.imageSliderData;
    },
    setImageSliderStartIndex(state, action) {
      state.imageSliderStartIndex = action.payload.imageSliderStartIndex;
    },
  },
});

export const timelineActions = timelineSlice.actions;
export default timelineSlice.reducer;
