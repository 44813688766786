/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  paymentId: null,
  paymentInfo: null,
  requestPayment: null,
  paymentResult: null,
  loading: true,
};

const paymentSlice = createSlice({
  name: "paymentInfo",
  initialState,
  reducers: {
    paymentId: (state, action) => {
      state.paymentId = action.payload;
    },
    paymentInfo: (state, action) => {
      state.paymentInfo = action.payload;
    },
    requestPayment: (state, action) => {
      state.requestPayment = action.payload;
    },
    paymentResult: (state, action) => {
      state.paymentResult = action.payload;
    },
    handleLoadingSpinner(state, action) {
      state.loading = action.payload.loading;
    },
    // registPostData(state, action) {
    //  state.newPostData = action.payload.newPostData;
    // },
  },
});

// export const statePost = function (state: any) {
//   // // console.log("statePost", state);
//   return state.postData;
// };
export const paymentActions = paymentSlice.actions;

export default paymentSlice.reducer;
