/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

type InitialStateType = {
  loading: boolean;
  unWrittenReview: [];
  writtenReview: [];
  beforeImgs: any[];
  afterImgs: any[];
  addedBeforeImgs: any[];
  addedAfterImgs: any[];
  deleteIndexOfAddedBeforeImgs: number;
  deleteIndexOfAddedAfterImgs: number;
};
const initialState: InitialStateType = {
  loading: true,
  unWrittenReview: [],
  writtenReview: [],
  beforeImgs: [],
  afterImgs: [],
  addedBeforeImgs: [],
  addedAfterImgs: [],
  deleteIndexOfAddedBeforeImgs: -1,
  deleteIndexOfAddedAfterImgs: -1,
};
const myReviewSlice = createSlice({
  name: "myreview",
  initialState,
  reducers: {
    handleLoadingSpinner(state, action) {
      state.loading = action.payload.loading;
    },

    getUnWrittenReview(state, action) {
      state.unWrittenReview = action.payload.unWrittenReview;
    },
    getWrittenReview(state, action) {
      state.writtenReview = action.payload.writtenReview;
    },

    initBeforeImgs(state, action) {
      state.beforeImgs = action.payload.beforeImgs;
    },
    deleteBeforeImgs(state, action) {
      state.beforeImgs.splice(action.payload.deleteIndex, 1);
    },
    addBeforeImgs(state, action) {
      state.addedBeforeImgs.push(action.payload.addedBeforeImgs);
    },
    deleteAddedBeforeImgs(state, action) {
      state.addedBeforeImgs.splice(action.payload.deleteIndex, 1);
      state.deleteIndexOfAddedBeforeImgs = action.payload.deleteIndex;
    },
    initAddedBeforeImgs(state) {
      state.addedBeforeImgs = [];
    },
    initAfterImgs(state, action) {
      state.afterImgs = action.payload.afterImgs;
    },
    deleteAfterImgs(state, action) {
      state.afterImgs.splice(action.payload.deleteIndex, 1);
    },
    addAfterImgs(state, action) {
      state.addedAfterImgs.push(action.payload.addedAfterImgs);
    },
    deleteAddedAfterImgs(state, action) {
      state.addedAfterImgs.splice(action.payload.deleteIndex, 1);
      state.deleteIndexOfAddedAfterImgs = action.payload.deleteIndex;
    },
    initAddedAfterImgs(state) {
      state.addedAfterImgs = [];
    },
  },
});

export const myReviewActions = myReviewSlice.actions;
export default myReviewSlice.reducer;
