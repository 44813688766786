import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import timelineSlice from "./timeline/timelineSlice";
import postDataSlice from "./payment/postDataSlice";
import loginSlice from "./login/loginSlice";
import meisterSlice from "./meister/meisterSlice";
import myReviewSlice from "./myReview/myReviewSlice";
import reviewSlice from "./review/reviewSlice";
import myinfoSlice from "./myinfo/myinfoSlice";
import searchSlice from "./search/searchSlice";
import userSignSlice from "./userSign/userSignSlice";
import locationSlice from "./location/locationSlice";
import promotionSlice from "./promotion/promotionSlice";
import estimateSlice from "./estimate/estimateSlice";
import paymentSlice from "./payment/paymentSlice";
import paytermsSlice from "./payterms/paytermsSlice";
import requestSlice from "./request/requestSlice";
import promoSlice from "./promo/promoSlice";

const store = configureStore({
  reducer: {
    timeline: timelineSlice,
    postData: postDataSlice,
    login: loginSlice,
    meister: meisterSlice,
    myreview: myReviewSlice,
    review: reviewSlice,
    myinfo: myinfoSlice,
    search: searchSlice,
    userSign: userSignSlice,
    location: locationSlice,
    promotion: promotionSlice,
    estimate: estimateSlice,
    payment: paymentSlice,
    payterms: paytermsSlice,
    request: requestSlice,
    promo: promoSlice,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export default store;
