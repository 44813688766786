/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // brand: [],
  meister: {},
  history: [],
  loading: true,
  // meister: {},
};

const meisterSlice = createSlice({
  name: "meister-data",
  initialState,
  reducers: {
    // getBrandData(state, action) {
    //   state.brand = action.payload.brand;
    // },
    getMeisterData(state, action) {
      state.meister = action.payload.meister;
    },
    getHistoryData(state, action) {
      state.history = action.payload.history;
    },
    handleLoadingSpinner(state, action) {
      state.loading = action.payload.loading;
    },
    // registPostData(state, action) {
    //  state.newPostData = action.payload.newPostData;
    // },
  },
});

export const stateMeister = function (state: any) {
  return state.meister;
};

export const meisterActions = meisterSlice.actions;
export default meisterSlice.reducer;
