/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
/* eslint-disable-next-line no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const userSignSlice = createSlice({
  name: "userSign",
  initialState: {
    errors: null,
    findId: {},
    findPw: {},
    certification: {},
    confirmCert: {},
    signUp: {
      mobile: null,
      password: null,
      userName: null,
      userGender: null,
      userBirth: null,
      userAgree: null,
    },
    loading: true,
    loading2: false,
  },
  reducers: {
    findUserId: (state, action) => {
      state.findId = action.payload;
    },
    findUserPw: (state, action) => {
      state.findPw = action.payload;
    },
    chkError: (state, action) => {
      state.errors = action.payload;
    },
    certification: (state, action) => {
      state.certification = action.payload;
    },
    confirmCert: (state, action) => {
      state.confirmCert = action.payload;
    },
    signUpMobile: (state, action) => {
      state.signUp.mobile = action.payload;
    },
    signUpPw: (state, action) => {
      state.signUp.password = action.payload;
    },
    signUpName: (state, action) => {
      state.signUp.userName = action.payload;
    },
    signUpGender: (state, action) => {
      state.signUp.userGender = action.payload;
    },
    signUpBirth: (state, action) => {
      state.signUp.userBirth = action.payload;
    },
    signUpAgree: (state, action) => {
      state.signUp.userAgree = action.payload;
    },
    signUp: (state, action) => {
      state.signUp = action.payload;
    },
    handleLoadingSpinner(state, action) {
      state.loading = action.payload.loading;
    },
    handleLoadingSpinner2(state, action) {
      state.loading2 = action.payload.loading2;
    },
  },
});

// export const stateusers = state => state.users;
// export const stateLogin = function (state: any) {
//   return state.login;
// };
// 변수값을 읽게 해주는 친구 for component
export const userSignActions = userSignSlice.actions;
// 변수값을 바꾸는 동작 = actions

export default userSignSlice.reducer;
// component에서 사용할 수 있도록 export = reducer
