/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
/* eslint-disable-next-line no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const paytermsSlice = createSlice({
  name: "payterms",
  initialState: {
    payterms: null,
    loading: true,
  },
  reducers: {
    getPayterms: (state, action) => {
      state.payterms = action.payload;
    },
    handleLoadingSpinner(state, action) {
      state.loading = action.payload.loading;
    },
  },
});

// export const stateusers = state => state.users;
// export const stateEstimate = function (state: any) {
//   return state.estimate;
// };
// 변수값을 읽게 해주는 친구 for component
export const paytermsActions = paytermsSlice.actions;
// 변수값을 바꾸는 동작 = actions

export default paytermsSlice.reducer;
// component에서 사용할 수 있도록 export = reducer
