/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
/* eslint-disable-next-line no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const estimateSlice = createSlice({
  name: "estimate",
  initialState: {
    estimate: null,
    loading: true,
    sort: "price",
  },
  reducers: {
    handleLoadingSpinner(state, action) {
      state.loading = action.payload.loading;
    },
    getEstimate: (state, action) => {
      state.estimate = action.payload;
    },
    sortHandler: (state, action) => {
      state.sort = action.payload;
    },
  },
});

// export const stateusers = state => state.users;
// export const stateEstimate = function (state: any) {
//   return state.estimate;
// };
// 변수값을 읽게 해주는 친구 for component
export const estimateActions = estimateSlice.actions;
// 변수값을 바꾸는 동작 = actions

export default estimateSlice.reducer;
// component에서 사용할 수 있도록 export = reducer
