/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  slideUpModalPopup: false,
  slideUpModalPopupBrand: false,
  selectedTag: null,
  clickedTag: "",
  checkedBrandList: [],
  selectedBrandList: null,
  searchKeyword: "",
  loading: true,
  imageSliderPopup: false,
  imageSliderData: null,
  imageSliderStartIndex: null,
  redirect: "",
};
const reviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {
    handleLoadingSpinner(state, action) {
      state.loading = action.payload.loading;
    },
    setSlideUpModalPopup(state, action) {
      state.slideUpModalPopup = action.payload.slideUpModalPopup;
    },
    // tag
    setSelectedTag(state, action) {
      state.selectedTag = action.payload.selectedTag;
    },
    setClickedTag(state, action) {
      state.clickedTag = action.payload.clickedTag;
    },
    // brand
    setSlideUpModalPopupBrand(state, action) {
      state.slideUpModalPopupBrand = action.payload.slideUpModalPopupBrand;
    },
    setCheckedBrandList(state, action) {
      if (action.payload.clear) {
        state.checkedBrandList = [];
      } else if (action.payload.sync) {
        if (state.selectedBrandList) {
          state.checkedBrandList = state.selectedBrandList;
        }
      } else if (state.checkedBrandList.includes(action.payload.brand)) {
        const index = state.checkedBrandList.indexOf(action.payload.brand);
        state.checkedBrandList.splice(index, 1);
      } else {
        state.checkedBrandList.push(action.payload.brand);
      }
    },
    setSelectedBrandList(state) {
      state.selectedBrandList = state.checkedBrandList;
    },
    // setSearchKeyword
    setSearchKeyword(state, action) {
      state.searchKeyword = action.payload.searchKeyword;
    },
    // imageSlider
    setImageSliderPopup(state, action) {
      state.imageSliderPopup = action.payload.imageSliderPopup;
    },
    setImageSliderData(state, action) {
      state.imageSliderData = action.payload.imageSliderData;
    },
    setImageSliderStartIndex(state, action) {
      state.imageSliderStartIndex = action.payload.imageSliderStartIndex;
    },
    // redirect
    setRedirectPath(state, action) {
      state.redirect = action.payload.redirect;
    },
  },
});

export const reviewActions = reviewSlice.actions;
export default reviewSlice.reducer;
