/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const searchSlice = createSlice({
  name: "search",
  initialState: {
    searchData: [],
  },
  reducers: {
    setSearch: (state, action) => {
      state.searchData = action.payload;
      // console.log("setSearch", state, action);
    },
  },
});

export const stateSearch = function (state: any) {
  // // console.log("stateMyInfo", state);
  return state.search;
};

export const actionSearch = searchSlice.actions;

export default searchSlice.reducer;
