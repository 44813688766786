/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const locationSlice = createSlice({
  name: "location",
  initialState: {
    isLocation: [],
    location: {},
  },
  reducers: {
    setLocation: (state, action) => {
      state.location = action.payload;
      // console.log("setLocation", state, action);
    },
  },
});

export const stateLocation = function (state: any) {
  return state.location;
};

export const actionLocation = locationSlice.actions;

export default locationSlice.reducer;
