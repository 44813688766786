/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
/* eslint-disable-next-line no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

function getCookie(name: any) {
  const matches = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  // const justDate = new Date("Thu Dec 08 2022 18:10:50 GMT+0900");
  return matches ? matches[2] : null;
}

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    isLogin: getCookie("Authorization") ? true : false,
    login: {
      name: null,
      file: null,
    },
    naver: null,
    google: null,
    kakao: null,
    apple: null,
    loading: false,
    error: null,
  },
  reducers: {
    setIsLogin: (state, action) => {
      state.isLogin = action.payload;
    },
    naverLogin: (state, action) => {
      state.naver = action.payload;
    },
    googleLogin: (state, action) => {
      state.google = action.payload;
    },
    kakaoLogin: (state, action) => {
      state.kakao = action.payload;
    },
    appleLogin: (state, action) => {
      state.apple = action.payload;
    },
    handleLoadingSpinner(state, action) {
      state.loading = action.payload.loading;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

// export const stateusers = state => state.users;
export const stateLogin = function (state: any) {
  return state.login;
};
// 변수값을 읽게 해주는 친구 for component
export const actionsLogin = loginSlice.actions;
// 변수값을 바꾸는 동작 = actions

export default loginSlice.reducer;
// component에서 사용할 수 있도록 export = reducer
