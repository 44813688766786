/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  postData: [],
  newPostData: [],
  loading: true,
};

const postDataSlice = createSlice({
  name: "control-post-data",
  initialState,
  reducers: {
    getPostData: (state, action) => {
      state.postData = action.payload;
    },
    deletePostData: (state, action) => {
      state.postData = action.payload;
    },
    handleLoadingSpinner(state, action) {
      state.loading = action.payload.loading;
    },
    // registPostData(state, action) {
    //  state.newPostData = action.payload.newPostData;
    // },
  },
});

export const statePost = function statePostFunc(state: any) {
  // // console.log("statePost", state);
  return state.postData;
};
export const getPostDataActions = postDataSlice.actions;

export default postDataSlice.reducer;
