import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  // baseURL: `https://api.luxurynall.com/api/v1`, // 상용계
  // baseURL: `https://tapi.luxurynall.com/api/v1`, // 개발계
  headers: {
    "Content-type": "application/json",
    // Authorization:
    // "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiY2I1YWEzZWI3NDhmZDQyZGE1NmEwYTFjMTNlOWEyZGFiYzAzOGNjNmY0ZWZkZWUxODE5NzNiYTVmYTI3MTcyMTc1ZjI1YmYyMmIwYzYyMTkiLCJpYXQiOjE2NjA2MTI5ODIuNDc2NTQ4LCJuYmYiOjE2NjA2MTI5ODIuNDc2NTUyLCJleHAiOjE2NjEyMTc3ODIuMzUyMTg5LCJzdWIiOiIzMDY5MSIsInNjb3BlcyI6WyIqIl19.U2-_-wdnIQ04zbsOFwPu_n2AZzcDpfeT-F5GHfuCyp872BI2Dc7kHp1wOFVsiaV0PTiJz1RWDLzm2B094sO_KTgY0W9xRkAI0ohRbMWKqQ1L8i9iJijSPpyhnwXBVFv5qIi1-v94p0Gwam6v-ZqgGxuzReMQVgym6wqT9KMs7WxC-OM7j_vkth7hHMSzwZW74uE27zXsi8W6RTKakZxF3AMJ_-gZtxKCiuC9MyprHZcb2wskEBD6tSvbVD2cqPdOCzsygdKbn1mkDyrkXZCyj3NNxjihwR7R1oP4Nue30-s3sdgIQ0ak6JvO8mPMb8I1jfZec4-maUtjAgHSLZuBc2w_ZkCxeJ_x32epNHUx3oHZPrv5zxFLAXWGQB9W8cKXw2JLGxi8XtGu78o7fpqx4J0WB0rSgHT6Z8m5FW-59jLDUIgkC760A22tke1zEaRY7jvTEzS-6yPpo5jtQGbqbNnX7uOAnIZ0LQ5gczkBbEoRDn7g8DC2Sq2gF-jezqfrfyzdiK5YiH3yYI6VvnRjNnXRUiNsISX2T2IIsV-KPwGjf39pxRELcN9wwt7pmZNbvAZq9MS6sDTBbp0VNLuozWpM2AmsiHzNjoqUM1cUNkZJmxQuDz_t4WHbJusIMh9WeaeuhjDEOhWZMENBS5T4eiuuGVbv2yyRMMOgY6f3pLI",
  },
});

// Add a request interceptor
api.interceptors.request.use(
  (config: any) => {
    // Do something before request is sent
    // console.log("request start", config);
    return config;
  },
  (error) => {
    // Do something with request error
    // console.log("request error", error);
    return Promise.reject(error);
  }
);
// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log("response start", response);
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // console.log("response error", error);
    return Promise.reject(error);
  }
);

export default api;
