/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const myInfoSlice = createSlice({
  name: "myInfo",
  initialState: {
    isMyInfo: [],
    mileage: {
      total: null,
    },
    notice: {},
    faq: {},
    myCoupon: null,
    myCouponRegist: null,
    loading: true,
    falseLoading: false,
    myRestorations: null,
    readCoupon: null,
    pushRead: null,
    promotionRead: null,
    withdraw: null,
  },
  reducers: {
    setMyInfo: (state, action) => {
      state.isMyInfo = action.payload;
      // console.log("setMyInfo", state, action);
    },
    setMileage: (state, action) => {
      state.mileage = action.payload;
      // console.log("setMileage", state, action);
    },
    setNoticeInfo: (state, action) => {
      state.notice = action.payload;
      // console.log("setNoticeInfo", state, action);
    },
    setFaq: (state, action) => {
      state.faq = action.payload;
      // console.log("setFaq", state, action);
    },
    setMyCoupon: (state, action) => {
      state.myCoupon = action.payload;
      // console.log("setCoupon", state, action);
    },
    setCoupon: (state, action) => {
      state.readCoupon = action.payload;
      // console.log("setCoupon", state, action);
    },
    setMyCouponRegist: (state, action) => {
      state.myCouponRegist = action.payload;
      // console.log("Regist", state);
    },
    handleLoadingSpinner(state, action) {
      // console.log("로딩 슬라이스", state, action);
      state.loading = action.payload.loading;
    },
    handleFalseLoadingSpinner(state, action) {
      state.falseLoading = action.payload.loading;
    },
    setMyRestorations(state, action) {
      state.myRestorations = action.payload;
    },
    setPushRead(state, action) {
      state.pushRead = action.payload;
    },
    setPromotionRead(state, action) {
      state.promotionRead = action.payload;
    },
    setWithDrawReason(state, action) {
      state.withdraw = action.payload;
    },
  },
});

// export const stateusers = state => state.users;
// state => redux state로 받을 이름: index에 등록한 이름
export const stateMyInfo = function (state: any) {
  // console.log("stateMyInfo", state);
  return state.myinfo;
};

// 변수값을 읽게 해주는 친구 for component
export const actionsMyInfo = myInfoSlice.actions;
// 변수값을 바꾸는 동작 = actions

export default myInfoSlice.reducer;
// component에서 사용할 수 있도록 export = reducer
