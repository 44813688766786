/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const promoSlice = createSlice({
  name: "promo",
  initialState: {
    isPromo: {},
  },
  reducers: {
    setPromoCheck: (state, action) => {
      state.isPromo = action.payload;
      // console.log("setPromoCheck", state, action);
    },
  },
});

// export const stateusers = state => state.users;
// state => redux state로 받을 이름: index에 등록한 이름
export const statePromoCheck = function (state: any) {
  return state.promo;
};

// 변수값을 읽게 해주는 친구 for component
export const actionsPromo = promoSlice.actions;
// 변수값을 바꾸는 동작 = actions

export default promoSlice.reducer;
// component에서 사용할 수 있도록 export = reducer
